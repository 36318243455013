@import "../../../styles/constants";

.headerBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 48px;
  margin-bottom: 16px;
}

.commandCenter {
  display: flex;
  justify-content: space-between;
}

.commandCenterLeft {
  display: flex;
}

.commandCenterName {
  font-size: 18px;
  font-family: $fontFamilyBold;
  color: #29333a;
  margin-right: 8px;
}

.commandCenterPhone {
  font-size: 20px;
  font-family: $fontFamilyBold;
  color: #001a70;
}


@mixin connection-status-label {
  height: 24px;
  padding:0 12px;
  text-align: center;
  color: #fff;
  border-radius: 20px;
  font-family: $fontFamilyExtraBold;
  font-size: 14px;
  line-height: 24px;
}

.labelConnected {
  @include connection-status-label;
  background-color: #00c168;
}

.labelDisconnected {
  @include connection-status-label;
  background-color: #ec655e;
}