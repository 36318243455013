@import "../../../styles/constants";

.patientInfo {
  flex: 1 1 648px;
  flex-basis: 648px;
  padding: 16px;
}

.patientInfoTitle {
  margin-bottom: 8px;
  font-size: 14px;
  font-family: $fontFamilyBold;
  line-height: 24px;
  color: #0aa6a8
}


.patientInfoDataContainer {
  display: flex;
  flex-direction: row;
}


.patientInfoDataColumn {
  width: 50%;
}

.patientInfoDataRow {
  display: flex;
  margin-bottom: 8px;
}

.fieldLabel {
  font-family: $fontFamilyMedium;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
  color: #545c61;
  min-width: 100px;
  max-width: 100px;

  svg {
    vertical-align: text-top;
    margin-right: 18px;
  }
}

.assignedNurse {
  display: flex;
  svg {
    min-width: 16px;
    margin-top: 4px;
  }

}

.addressField {
  min-width: 82px;
}

.fieldValue {
  font-family: $fontFamilyBold;
  font-size: 14px;
  line-height: 24px;
  color: #29333a;
  word-break: break-word;
}


