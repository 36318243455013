@import "./styles/constants.scss";

html, body {
  height: 100%;
}

#root, .App {
  height: 100%;
}


body {
  background-color: $light-grey;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: 'HKNovaRegular';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 200;
  src: url('./fonts/Hk-Nova-Regular.ttf') format('truetype');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
}

@font-face {
  font-family: 'HKNovaMedium';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 500;
  src: url('./fonts/HKNova-Medium.woff') format('woff');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
}

@font-face {
  font-family: 'HKNovaBold';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 600;
  src: url('./fonts/HK-Nova-Bold.otf') format('opentype');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
}

@font-face {
  font-family: 'HKNovaExtraBold';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 600;
  src: url('./fonts/HK-Nova-ExtraBold.otf') format('opentype');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
}





