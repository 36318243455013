@import "../../../styles/constants";

.contactsInfo {
  flex-basis: 648px;
  flex-grow: 2;
  padding: 16px 0 0 16px;
}


.contactInfoFlexContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
}

.contactInfoDataContainer {
  padding: 16px 32px;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  background-color: rgba(#7f8589, 5%)
}

.contactColumnTitle {
  font-family: $fontFamilyBold;
  color: #0aa6a8;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;

}

.contactFieldLabelColumn {
  color: #545c61;
  font-family: $fontFamilyMedium;
  margin-right: 16px;
  font-size: 14px;
  line-height: 24px;
}


.contactFieldValueColumn {
  font-family: $fontFamilyBold;
  color: #29333a;
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;
}


.contactFieldDivider {
  margin-bottom: 8px;
}

.contactInfoDataColumnBody {
  display: flex;
}

.contactInfoDataColumn {
  margin-right: 48px;
}


@media screen and (max-width: 1600px) {
  .contactInfoDataContainer {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .contactInfoDataColumn{
    flex:50%;
  }

}
