@import "../../../styles/constants";

.callsListContainer {
  padding: 16px 24px;
  min-width: 1280px;
}

.callItemContainer {
  margin: 16px 0;
  padding: 16px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.contentBlock {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.loadingContainer {
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 30vh;
}

.noData {
  font-size: 22px;
  font-family: $fontFamilyBold;
  color: #29333a;
  text-align: center;
  margin-top: 40vh;
}
