@import "../../../../styles/constants";

.callItemHeader {
  border-radius: 4px;
  background-color: #e5e7f2;
  min-height: 48px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  display: flex;
}


.unrecognizedCallBackground {
  background-color: rgba(#fbcc0f, 10%);;
}

.emergencyCallBackground {
  background-color: #fdefee;
}

.callItemHeaderLeft {
  align-items: center;
  display: flex;
  flex-basis: 840px;
  justify-content: space-between;
}

.callItemHeaderPatientName {
  display: flex;
  align-items: center;
  color: #001a70;
  font-size: 16px;
  font-family: $fontFamilyBold;
}

.callIcon {
  height: 27px;
  width: auto;
  margin-right: 18px;
}

.callTypeEmergencyLabel {
  font-size: 14px;
  font-family: $fontFamilyBold;
  position: absolute;
  right: 8px;
  padding: 4px 8px;
  color: #fff;
  line-height: 24px;
  background-color: #ec655e;
}

.callItemHeaderPhone {
  color: #001a70;
  font-size: 14px;
  letter-spacing: 0;
  font-family: $fontFamilyExtraBold;
}

.callTypeEmergencyText {
  color: #ec655e;
}

.callTypeUnrecognizedText {
  color: #29333a
}

