@import "../../styles/constants.scss";

.loginPageContainer {
  min-height: 100vh;
  background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right top, #19aead, #0093b0, #0077ae, #0058a1, #2a3585);
}


.loginBlockContainer {
  height: 656px;
  width: 512px;
  padding: 28px 32px 48px 32px;
  position: relative;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  margin-top: 32px;
}

.mhImage {
  margin: auto auto 44px auto;
  height: 120px;
  width: auto;
  display: block;
}

.loginTitle {
  font-size: 24px;
  line-height: 48px;
  font-family: $fontFamilyExtraBold;
  text-align: center;
  margin-bottom: 32px;
}

.error {
  color: #ec655e;
  font-family: $fontFamilyExtraBold;
  text-align: center;
  font-size: 24px;
  padding:10px;
}

.accessCodeContainer {
  background-color: rgba(#001575, 5%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 32px;
}

.loginTextField {
  height: 64px;
  width: 386px;
  display: flex;
  align-items: center;
  padding: 16px 32px 16px 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.loginTextFieldIconContainer {
  height: 64px;
  width: 64px;
  background-color: #0aa6a8;
  display: flex;
  align-items: center;
  justify-content: center;
}


.loginTextFieldInput {
  flex: 1;
  height: 32px;
  margin-left: 32px;
}


.loginButton {
  width: 144px;
  height: 64px;
  position: absolute;
  background-color: #0aa6a8;
  color: #ffffff;
  bottom: 48px;
  right: 32px;
}

.copyright {
  color: #ffffff;
  font-family: $fontFamilyMedium;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin: 32px 0;
}