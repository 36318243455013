@import "../../../../styles/constants";

.callTimeDiff {
  font-size: 14px;
  font-family: $fontFamilyMedium;
  color: #545c61;
}

.callTime {
  font-size: 16px;
  font-family: $fontFamilyBold;
  color: #29333a;
  margin-right: 16px;
  display: flex;
}

.originatingPhone {
  margin-right: 84px;
  font-size: 16px;
  font-family: $fontFamilyBold;
  color: #29333a;
  display: flex;
}

.phoneAndTimeContainer {
  display: flex;
  align-items: flex-end;
}

.phoneSvgEl {
  height: 18px;
  margin-right: 18px;
}

.timeSvgEl {
  height: 20px;
  margin-right: 18px;
}